import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="stages--internal-interviews--work-experience-form"
export default class extends Controller {
  static targets = ["endDateContainer", "currentJob"]
  connect() {
  }

  currentJobChanged() {
    console.log("currentJobChanged", this.currentJobTarget.checked);

    if (this.currentJobTarget.checked) {
      this.endDateContainerTarget.classList.add("d-none")
    } else {
      this.endDateContainerTarget.classList.remove("d-none")
    }
  }
}
