import {Controller} from '@hotwired/stimulus'
import {showCollapsible, hideCollapsible} from "../utils/collapsible";
export default class extends Controller {
  static targets = ['target', 'template', 'addButton']
  static values = {
    max: {
      type: Number,
      default: 100
    },
    min: {
      type: Number,
      default: 1
    },
    wrapperSelector: {
      type: String,
      default: '.nested-form-wrapper'
    }
  }

  connect() {
    this._showOrHideAddButton();
    this._showOrHideRemoveButtons();
  }

  get _count() {
    let count = 0;

    for (const wrapper of this.element.querySelectorAll(this.wrapperSelectorValue)) {
      if (wrapper.style.display !== 'none') {
        count += 1;
      }
    }

    return count;
  }

  _showOrHideAddButton() {
    if (!this.hasAddButtonTarget) {
      return;
    }

    if (this._count >= this.maxValue) {
      this.addButtonTarget.setAttribute('disabled', 'disabled');
    } else {
      this.addButtonTarget.removeAttribute('disabled');
    }
  }

  _showOrHideRemoveButtons() {
    /** @type {NodeListOf<HTMLButtonElement>} */
    const removeButtons = this.element.querySelectorAll("button[data-action='nested-form#remove']");

    if (removeButtons.length === 0) {
      return;
    }

    for (const button of removeButtons) {
      if (this._count <= this.minValue) {
        button.setAttribute('disabled', 'disabled');
        button.classList.add("d-none");
      } else {
        button.removeAttribute('disabled');
        button.classList.remove("d-none");
      }
    }
  }

  add(e) {
    e.preventDefault()

    const content = this.templateTarget.innerHTML
      .replaceAll(/NEW_RECORD/g, new Date().getTime().toString());
    this.targetTarget.insertAdjacentHTML('beforebegin', content)

    // Check if there's a collapsible element
    const collapsible = this.element.querySelector(".collapse:not(.show)");
    if (collapsible) {
      showCollapsible(collapsible);
    }

    this._showOrHideAddButton();
    this._showOrHideRemoveButtons();
  }


  remove(e) {
    e.preventDefault()

    // @ts-ignore
    const wrapper = e.target.closest(this.wrapperSelectorValue)

    if (wrapper.dataset.newRecord === 'true') {
      this._removeWrapper(wrapper).then(() => {
        this._showOrHideRemoveButtons();
      });
    } else {
      this._hideWrapper(wrapper);

      const input = wrapper.querySelector("input[name*='_destroy']")
      input.value = '1'

      this._showOrHideRemoveButtons();
    }

    this._showOrHideAddButton();
  }


  _hideWrapper(wrapper) {
    if (
      wrapper.classList.contains('collapse')
    ) {
      hideCollapsible(wrapper);
    } else {
      wrapper.style.display = 'none'
    }
  }

  _removeWrapper(wrapper) {
    return new Promise((resolve) => {
      if (
        wrapper.classList.contains('collapse')
      ) {
        hideCollapsible(wrapper).then(() => {
          wrapper.remove()
          resolve();
        });
      } else {
        wrapper.remove()
        resolve();
      }
    });
  }

}
